
import { Options, Vue, prop } from 'vue-class-component'

@Options({
    components: {},
})
export default class TermsOfService extends Vue {
    async created() {
        //
    }
}
